<template>
    <el-row class="warp">
        <el-col :span="24" class="warp-breadcrum">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }"><b>首页</b></el-breadcrumb-item>
                <el-breadcrumb-item>商务合作</el-breadcrumb-item>
            </el-breadcrumb>
        </el-col>
        <el-col :span="24" class="warp-main">
            <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
                <el-form :inline="true" :model="filters">
                    <el-form-item>
                        <el-input v-model="filters.name" placeholder="请输入姓名" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="filters.contactNumber" placeholder="请输入手机号" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-on:click="getCooperationPage" icon="search">查询</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-on:click="goReset" icon="search">重置</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
            <el-table :data="cooperation" highlight-current-row v-loading="listLoading" @selection-change="selsChange" style="width: 100%;" border>
                <!--<el-table-column type="selection" width="50" align="center"></el-table-column>-->
                <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                <el-table-column prop="name" label="姓名" width="100" align="center"></el-table-column>
                <el-table-column prop="nickName" label="昵称" width="180" align="center"></el-table-column>
                <el-table-column prop="contactNumber" label="电话" width="120" align="center"></el-table-column>
                <el-table-column prop="cooperationContent" label="内容" min-width="500"></el-table-column>
                <el-table-column prop="formatCreateTime" label="创建时间" width="180" align="center" sortable></el-table-column>
            </el-table>
            <el-col :span="24" class="table-footer">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes=[10,15,20,25,50] :page-size="pageSize" :current-page.sync="pageNum" layout="total, sizes, prev, pager, next, jumper" :total="total" style="float:right;">
                </el-pagination>
            </el-col>
        </el-col>
    </el-row>
</template>
<script>
    import {reqGetCooperationListPage} from '../../api/cooperation-api'

    export default {
        data() {
            return {
                filters: {
                    name: '',
                    contactNumber:'',
                },
                cooperation: [],
                total: 0,
                pageNum: 1,
                pageSize: 15,
                listLoading: false,
                sels: [],
            }
        },

        methods: {
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getCooperationPage();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.getCooperationPage();
            },
            //重置
            goReset() {
                this.filters.name = '';
                this.filters.contactNumber = '';
                this.pageNum = 1;
                this.getCooperationPage();
            },

            selsChange: function (sels) {
                this.sels = sels;
            },
            getCooperationPage() {
                var that = this;
                that.listLoading = true;
                let para = {
                    pageNum: that.pageNum,
                    pageSize: that.pageSize,
                    name: that.filters.name,
                    contactNumber: that.filters.contactNumber,
                };
                reqGetCooperationListPage(para).then((res) => {
                    if (res.data.code === 1) {
                        that.total = res.data.data.total;
                        that.cooperation = res.data.data.records;
                    } else {
                        that.$message({
                            message: '查询失败',
                            type: 'error'
                        });
                    }
                    that.listLoading = false;
                }).catch(() => {
                    that.$message({
                        message: '查询失败',
                        type: 'error'
                    });
                })
            },
        },
        mounted() {
            this.getCooperationPage();
        }
    }
</script>
