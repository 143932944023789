import axios from 'axios'
import {SERVER_HOST} from './../common/portConfig'
var instance = axios.create({
    headers: {
        'Authorization': ''
    }
});
instance.interceptors.request.use(
    config => {
        config.headers.Authorization = sessionStorage.getItem('Authorization');
        return config
    }, err => {
        return Promise.reject(err)
    });

//商务合作

export const reqGetCooperationListPage = params => { return instance.get(`${SERVER_HOST}/admin/businessCooperation/list`,{ params: params }) };
